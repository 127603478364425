"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeMembershipToJSON = exports.ChallengeMembershipFromJSONTyped = exports.ChallengeMembershipFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeMembershipFromJSON(json) {
    return ChallengeMembershipFromJSONTyped(json, false);
}
exports.ChallengeMembershipFromJSON = ChallengeMembershipFromJSON;
function ChallengeMembershipFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'challenge': (0, _1.ChallengeFromJSON)(json['challenge']),
        'prizeWon': !(0, runtime_1.exists)(json, 'prizeWon') ? undefined : (0, _1.PrizePoolFromJSON)(json['prizeWon']),
        'rewardClaimDate': !(0, runtime_1.exists)(json, 'rewardClaimDate') ? undefined : json['rewardClaimDate'],
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.ChallengeMembershipFromJSONTyped = ChallengeMembershipFromJSONTyped;
function ChallengeMembershipToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'challenge': (0, _1.ChallengeToJSON)(value.challenge),
        'prizeWon': (0, _1.PrizePoolToJSON)(value.prizeWon),
        'rewardClaimDate': value.rewardClaimDate,
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.ChallengeMembershipToJSON = ChallengeMembershipToJSON;
