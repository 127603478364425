"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeToJSON = exports.ChallengeFromJSONTyped = exports.ChallengeFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeFromJSON(json) {
    return ChallengeFromJSONTyped(json, false);
}
exports.ChallengeFromJSON = ChallengeFromJSON;
function ChallengeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'name': json['name'],
        'description': json['description'],
        'backgroundImageLarge': !(0, runtime_1.exists)(json, 'backgroundImageLarge') ? undefined : json['backgroundImageLarge'],
        'backgroundImageSmall': !(0, runtime_1.exists)(json, 'backgroundImageSmall') ? undefined : json['backgroundImageSmall'],
        'brandLogo': !(0, runtime_1.exists)(json, 'brandLogo') ? undefined : json['brandLogo'],
        'brandColor': !(0, runtime_1.exists)(json, 'brandColor') ? undefined : json['brandColor'],
        'rewards': (json['rewards'].map(_1.ChallengeRewardFromJSON)),
        'start': json['start'],
        'end': json['end'],
        'hasEnded': !(0, runtime_1.exists)(json, 'hasEnded') ? undefined : json['hasEnded'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'instantRewardQuest': !(0, runtime_1.exists)(json, 'instantRewardQuest') ? undefined : json['instantRewardQuest'],
    };
}
exports.ChallengeFromJSONTyped = ChallengeFromJSONTyped;
function ChallengeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'name': value.name,
        'description': value.description,
        'backgroundImageLarge': value.backgroundImageLarge,
        'backgroundImageSmall': value.backgroundImageSmall,
        'brandLogo': value.brandLogo,
        'brandColor': value.brandColor,
        'rewards': (value.rewards.map(_1.ChallengeRewardToJSON)),
        'start': value.start,
        'end': value.end,
        'hasEnded': value.hasEnded,
        'isActive': value.isActive,
        'instantRewardQuest': value.instantRewardQuest,
    };
}
exports.ChallengeToJSON = ChallengeToJSON;
