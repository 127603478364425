"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCustomizationItemToJSON = exports.MenuCustomizationItemFromJSONTyped = exports.MenuCustomizationItemFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCustomizationItemFromJSON(json) {
    return MenuCustomizationItemFromJSONTyped(json, false);
}
exports.MenuCustomizationItemFromJSON = MenuCustomizationItemFromJSON;
function MenuCustomizationItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'otherIds': !(0, runtime_1.exists)(json, 'otherIds') ? undefined : json['otherIds'],
        'menuCustomization': json['menuCustomization'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'minAllowed': !(0, runtime_1.exists)(json, 'minAllowed') ? undefined : json['minAllowed'],
        'maxAllowed': !(0, runtime_1.exists)(json, 'maxAllowed') ? undefined : json['maxAllowed'],
        'isAlcohol': !(0, runtime_1.exists)(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'serviceAvailabilities': !(0, runtime_1.exists)(json, 'serviceAvailabilities') ? undefined : (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
        'defaultQuantity': !(0, runtime_1.exists)(json, 'defaultQuantity') ? undefined : json['defaultQuantity'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'customizations': !(0, runtime_1.exists)(json, 'customizations') ? undefined : (json['customizations'].map(_1.MenuCustomizationFromJSON)),
        'caloriesBase': !(0, runtime_1.exists)(json, 'caloriesBase') ? undefined : json['caloriesBase'],
        'caloriesMax': !(0, runtime_1.exists)(json, 'caloriesMax') ? undefined : json['caloriesMax'],
        'oloId': !(0, runtime_1.exists)(json, 'oloId') ? undefined : json['oloId'],
        'oloChainId': !(0, runtime_1.exists)(json, 'oloChainId') ? undefined : json['oloChainId'],
        'chowlyId': !(0, runtime_1.exists)(json, 'chowlyId') ? undefined : json['chowlyId'],
        'chowlyExternalId': !(0, runtime_1.exists)(json, 'chowlyExternalId') ? undefined : json['chowlyExternalId'],
        'itsaCheckmateId': !(0, runtime_1.exists)(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}
exports.MenuCustomizationItemFromJSONTyped = MenuCustomizationItemFromJSONTyped;
function MenuCustomizationItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'otherIds': value.otherIds,
        'menuCustomization': value.menuCustomization,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'taxRate': value.taxRate,
        'minAllowed': value.minAllowed,
        'maxAllowed': value.maxAllowed,
        'isAlcohol': value.isAlcohol,
        'isEnabled': value.isEnabled,
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
        'defaultQuantity': value.defaultQuantity,
        'sortOrder': value.sortOrder,
        'customizations': value.customizations === undefined ? undefined : (value.customizations.map(_1.MenuCustomizationToJSON)),
        'caloriesBase': value.caloriesBase,
        'caloriesMax': value.caloriesMax,
        'oloId': value.oloId,
        'oloChainId': value.oloChainId,
        'chowlyId': value.chowlyId,
        'chowlyExternalId': value.chowlyExternalId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}
exports.MenuCustomizationItemToJSON = MenuCustomizationItemToJSON;
